<template>
    <LiefengContent>
        <template v-slot:title>趣智西关人文逢源数据统计</template>
        <template v-slot:toolsbarRight></template>
        <template v-slot:contentArea>
            <div class="content-div">
                <div class="text-title">趣智西关人文逢源数据统计</div>
                <div v-if="tableData && tableData.length">
                    <div class="title-div">
                        {{ "总访问量" }}
                        <span style="color: #0fb26c; font-size: 24px">{{ total || 0 }}</span>
                        <span style="margin-left: 5px">人次</span>
                    </div>
                    <div class="org-div">
                        <Card class="item" v-for="(item, index) in tableData" :key="index">
                            <div class="num">{{ item.name || "" }}</div>
                            <div class="text">{{ item.num || "0" }}</div>
                            <div class="text" style="font-size: 16px; font-weight: normal">
                                昨日
                                <span style="margin-left: 10px">{{ item.lastNum || "0" }}</span>
                            </div>
                        </Card>
                    </div>
                </div>
                <div>
                    <div class="title-div">
                        {{ "总参与量" }}
                        <span style="color: #0fb26c; font-size: 24px">{{ otherTotal || 0 }}</span>
                        <span style="margin-left: 5px">人次</span>
                    </div>
                    <div class="org-div">
                        <Card class="item" v-for="(item, index) in otherList" :key="index">
                            <div class="num">{{ item.name || '' }}</div>
                            <div class="text">{{ item.total || "0" }}</div>
                            <div class="text" style="font-size: 16px; font-weight: normal">
                                昨日
                                <span style="margin-left: 10px">{{ item.lastTotal || "0" }}</span>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
export default {
    components: {
        LiefengContent,
    },
    data() {
        return {
            //表头列表
            tableData: [],
            total: 0,
            otherList: [],
            otherTotal: 0,
        }
    },
    async created() {
        this.$Message.loading({
            content: "正在加载数据，请稍等",
        })
        await this.getList()
        await this.selectAction()
        await this.selectColumnDateView()
        this.$Message.destroy()
    },
    methods: {
        async getList() {
            await this.$get("/gateway/api/syscenic/app/page/selectView").then(res => {
                if (res && res.dataList) {
                    let total = 0
                    this.tableData = []
                    res.dataList.map(item => {
                        total = total + (Number(item.num) || 0)
                        if (item.pageCode == "1") {
                            this.tableData.push({
                                name: "智慧导赏累计访问量",
                                num: item.num || 0,
                                lastNum: item.lastNum || 0,
                            })
                        } else if (item.pageCode == "2") {
                             this.tableData.push({
                                name: "趣游逢源任务包累计访问量",
                                num: item.num || 0,
                                lastNum: item.lastNum || 0,
                            })
                        } else if (item.pageCode == "3") {
                            this.tableData.push({
                                name: "趣游逢源辅助任务包累计访问量",
                                num: item.num || 0,
                                lastNum: item.lastNum || 0,
                            })
                        }
                    })
                    this.total = total
                } else {
                    this.tableData = []
                    this.total = 0
                }
            })
        },
        async selectAction() {
            await this.$get("/gateway/api/syscenic/pc/page/selectAction").then(res => {
            if (res && res.dataList) {
                let total = 0
                this.otherList = res.dataList.map(item => {
                    total = total + (Number(item.total) || 0)
                    return {
                        ...item,
                    }
                })
                this.otherTotal = total
            } else {
                this.otherTotal = 0
                this.otherList = []
            }
            })
        },

        async selectColumnDateView() {
            await this.$get("/gateway/api/bi/manage/userAction/selectColumnDateView", {
                columnCodeList: "0001817,0001818,0001805",
            }).then(res => {
            if (res && res.dataList) {
                let total = 0
                res.dataList.map(item => {
                    if (item.columnCode == "0001817") {
                        item.name = "加入联盟累计访问量"
                        this.otherList.push({
                            name: "加入联盟参与量",
                            total: item.num && JSON.stringify(item.num) != "{}" ? item.num.pv : 0 || 0,
                            lastTotal: item.lastNum && JSON.stringify(item.lastNum) != "{}" ? item.lastNum.pv : 0 || 0,
                        })
                    } else if (item.columnCode == "0001818") {
                        item.name = "联盟花絮累计访问量"
                    } else if (item.columnCode == "0001805") {
                        item.name = "宣传视频累计访问量"
                    }
                    if (item.lastNum && JSON.stringify(item.lastNum) != "{}") {
                        item.lastNum = (Number(item.lastNum.share) || 0) + (Number(item.lastNum.uv) || 0) + (Number(item.lastNum.like) || 0)
                    }
                    if (item.num && JSON.stringify(item.num) != "{}") {
                        item.num = (Number(item.num.share) || 0) + (Number(item.num.uv) || 0) + (Number(item.num.like) || 0)
                    }
                    total = total + (Number(item.num) || 0)
                    this.tableData.push(item)
                })
                this.total = this.total + total

                let totalssss = 0
                this.otherList.map(item => {
                    totalssss = totalssss + (Number(item.total) || 0)
                })
                this.otherTotal = totalssss
            }
            })
        },
    },
}
</script>
    
<style scoped lang='less'>
.content-div {
    .text-title {
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 30px;
        padding: 20px;
    }
    .title-div {
        margin: 10px 20px -16px 20px;
        font-size: 18px;
        font-weight: bold;
        line-height: 40px;
        color: #287aad;
        span {
            margin-left: 5px;
        }
    }
    .org-div {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .item {
            width: 21%;
            margin: 10px 20px;
            text-align: center;
            padding: 10px;
            .num {
                // font-weight: bold;
                font-size: 16px;
                margin-bottom: 5px;
            }
            .text {
                margin-top: 10px;
                font-size: 26px;
                color: #0fb26c;
                font-weight: bold;
            }
        }
    }
}
</style>